const en = {
    translation: {
        navbar: {
            home: "Home",
            rentalDanang: "Motorbike Rental - Da Nang",
            rentalNhatrang: "Motorbike Rental - Nha Trang",
            contact: "Contact",
            call_now: "Call Us Today!",
            // phone_number: "0848 770 770",
        },
        banner: {
            sloganHeader: "TAKING RIDES TO A NEWER LEVEL",
            titleHeader: "FIND YOUR MOTORCYCLE",
            titleDN: "DA NANG AREA",
            titleNT: "NHA TRANG AREA",
            contentHeader: "Find your ideal motorbike at T-Bike Vietnam",
            search: "You are traveling in ",
            delivery: "24/7 DELIVERY",
            locationDaNang: "Da Nang",
            locationNhaTrang: "Nha Trang",
        },
        about: {
            titleAbout: "Great experiences",
            title1: "MODERN STYLE",
            title2: "ABSOLUTE SAFETY",
            title3: "DIVERSE OPTIONS",
            content1: "We are proud to bring you modern and trendy car models, meeting all your lifestyles.",
            content2: "Rental cars are regularly inspected and carefully maintained, ensuring absolute safety for all customers.",
            content3: "With many models and designs, we bring perfect choices, suitable for the personality and preferences of each customer.",
        },
        benefit: {
            titleBenefit: "Benefits of T - BIKE",
            title1: "Multiple Options",
            title2: "Convenient",
            title3: "Competitive Pricing",
            title4: "Reliable",
            title5: "24/7 Support",
            title6: "Insurance",
            content1: "Hundreds of diverse vehicles in many locations across the country, suitable for all your purposes",
            content2: "Easily search, compare and book the car you want with just a few clicks",
            content3: "Rental prices are publicly listed and up to 10% cheaper than traditional prices",
            content4: "All vehicles are less than 3 years old and regularly maintained",
            content5: "Customer support staff available throughout the rental process",
            content6: "Peace of mind with self-drive car rental insurance packages throughout the rental process",
        },
        booking: {
            titleBooking: "Book a Car at T - BIKE",
            title1: "Book a Car",
            title2: "Get a Car",
            title3: "Enjoy",
            content1: "Quickly book a car of your choice through our Website or App",
            content2: "Get a car at home or at our local dealers",
            content3: "All our vehicles meet safety standards",
        },
        hightlight: {
            slogan: "HIGHLIGHTS",
            titleL: "IMPROVING A FRIENDLY AND EASY DRIVING EXPERIENCE",
            contentL: "We at T-BIKE always want to bring you safe and comfortable trips. Friendly design, easy to use, bringing a smooth and inspiring journey. Let every drive be a memorable experience!",
            text1: "Explore freely with motorbike and car rental services.",
            text2: "Pick up and return your vehicle right in the city center, convenient and easy.",
            text3: "24/7 support team, always ready to help you.",
            titleR1: "Quick procedures, clear contracts",
            titleR2: "No time constraints",
            titleR3: "Fast delivery and return of vehicles to your door",
            contentR1: "With CCCD and BLX, you are eligible to use our car rental service.",
            contentR2: "Unlike other units, we charge car rental fees by 24 hours, helping you save costs compared to calculating by day from morning to afternoon.",
            contentR3: "Our experienced staff will deliver the car to your location within Da Nang city in just 15-30 minutes, no deposit required.",
        },
        introduce: {
            slogan: "PRESTIGE IS FIRST",
            title: "IMPROVING A FRIENDLY AND EASY DRIVING EXPERIENCE",
            content: "We at T-BIKE always want to bring you safe and comfortable trips. Friendly design, easy to use, bringing a smooth and inspiring journey. Let every ride be a memorable experience!",
            text1: "EASY TO BOOK",
            text2: "LONGER TRIPS ALL DAY",
            text3: "RECEIVE UNLIMITED MILES",
        },
        category: {
            titleCategory: "T-BIKE motorbike - car category",
            content: "All of our motorbikes - cars for rent are high-end models. In addition, after the contract with the customer ends, the vehicles will be inspected, maintained, replaced with damaged parts and must meet vehicle safety standards before being delivered to the new customer.",
            book: "Book a car now!",
            seemore: "SEE MORE",
        },
        rentalDaNang: {
            category: "Category",
            motorbikes: "Motorcycle",
            manualMotorcycle: "Manual Motorcycle",
            scooters: "Scooter",
            car: "Car",
            booking: "Book Now",
        },
        tourist: {
            title: "Tourist attraction",
        },
        contact: {
            title: "CONTACT US",
            hotlineDN: "Hotline Đa Nang",
            hotlineNT: "Hotline Nha trang",
            time: "Operating Hours",
            email: "Reach us by email",
            phone: "0848 770 770",
            location: "Our Address",
            beginTime: "Mon - Fri 9.30am - 8pm",
            lastedTime: "Sat - Sun 10am - 7pm",
            contentEmail: "tbikedn@gmail.com",
            contentLocationDN: "110 Tran Van Du, My An, Ngu Hanh Son, Da Nang City",
            contentLocationNT: "No. 9 Bach Dang, Phuoc Tien Ward, Nha Trang City",
            sendMail: "SEND MESSAGE",
            fullName: "Full Name",
            email: "Email",
            subject: "Subject",
            message: "Message",
        },
        footer: {
            lastestNews: "LEAVE INFORMATION",
            subscribe: "SIGN UP FOR UPDATES",
            content: "T-BIKE We are committed to providing quality, absolutely safe motorbike and car rental services. Vehicles are regularly maintained to ensure the best performance. Let T-BIKE accompany you on every journey!",
            contact: "CONTACT",
            location: "110 Tran Van Du, My An, Ngu Hanh Son, Da Nang City",
            location2: "286 Tran Hung Dao, An Hai Tay, Son Tra, Da Nang City",
            location3: "No. 9 Bach Dang, Phuoc Tien Ward, Nha Trang City",
            phone: "0848 770 770",
            mail: "tbikedn@gmail.com",
            website: "www.tbikevietnam.com",
            about: "INTRODUCTION TO T - BIKE",
            phone: "Phone Number"
        },
        slider: {
            title: "REAL PICTURES",
        }
    }
};

export default en;
