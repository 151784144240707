const vi = {
    translation: {
        navbar: {
            home: "Trang chủ",
            rentalDanang: "Cho Thuê Xe Máy - Đà Nẵng",
            rentalNhatrang: "Cho Thuê Xe Máy - Nha Trang",
            contact: "Liên hệ",
            call_now: "Đặt xe ngay!",
            // phone_number: "0848 770 770",
        },
        banner: {
            sloganHeader: "ĐƯA CHUYẾN ĐI LÊN MỘT TẦM CAO MỚI",
            titleHeader: "TÌM XE MÁY CỦA BẠN",
            titleDN: "KHU VỰC ĐÀ NẴNG",
            titleNT: "KHU VỰC NHA TRANG",
            contentHeader: "Tìm chiếc xe máy lý tưởng của bạn tại T-Bike Việt Nam",
            search: "Bạn đang đi du lịch ở",
            delivery: "GIAO XE TẬN NƠI 24/7",
            locationDaNang: "Đà Nẵng",
            locationNhaTrang: "Nha Trang",
        },
        about: {
            titleAbout: "Trải Nghiệm Tuyệt Vời",
            title1: "PHONG CÁCH HIỆN ĐẠI",
            title2: "AN TOÀN TUYỆT ĐỐI",
            title3: "ĐA DẠNG LỰA CHỌN",
            content1: "Chúng tôi tự hào mang đến cho bạn những mẫu xe hiện đại và thời thượng, đáp ứng mọi phong cách sống của bạn.",
            content2: "Những dòng xe cho thuê được kiểm tra định kỳ và bảo dưỡng kỹ lưỡng, đảm bảo an toàn tuyệt đối cho mọi khách hàng.",
            content3: "Với nhiều mẫu mã và kiểu dáng, chúng tôi mang đến những lựa chọn hoàn hảo, phù hợp với cá tính và sở thích của từng khách hàng.",
        },
        benefit: {
            titleBenefit: "Lợi ích của T - BIKE",
            title1: "Nhiều lựa chọn",
            title2: "Thuận tiện",
            title4: "Tin cậy",
            title5: "Hỗ trợ 24/7",
            title6: "Bảo hiểm",
            content1: "Số lượng xe đa dạng, phục vụ tại nhiều điểm trên cả nước, đáp ứng kịp thời và nhanh chóng cho nhu cầu của bạn.",
            content2: "Dễ dàng tìm kiếm, so sánh và đặt chiếc xe như ý với chỉ vài click chuột",
            content4: "Các xe được cung cấp luôn đảm bảo chất lượng tốt nhất, hỗ trợ đổi xe nếu như bạn gặp trục trặc trên đường 24/7",
            content5: "Có nhân viên hỗ trợ khách hàng trong suốt quá trình thuê xe",
            content6: "An tâm với các gói bảo hiểm thuê xe tự lái trong suốt quá trình thuê xe",
        },
        booking: {
            titleBooking: "Đặt Xe Tại T - BIKE ",
            title1: "Đặt Xe",
            title2: "Nhận Xe",
            title3: "Tận Hưởng",
            content1: "Nhanh chóng đặt một chiếc xe ưng ý thông qua Website hoặc Ứng dụng (App) của chúng tôi",
            content2: "Nhận xe tại nhà hoặc các đại lý trong khu vực của chúng tôi",
            content3: "Tất cả các phương tiện của chúng tôi đều đạt chuẩn an toàn",
        },
        hightlight: {
            slogan: "ĐIỂM NỔI BẬT",
            titleL: "NÂNG TẦM TRẢI NGHIỆM LÁI THÂN THIỆN VÀ DỄ DÀNG",
            contentL: "T-BIKE chúng tôi luôn mong muốn mang lại cho quý khách những chuyến đi an toàn và thoải mái. Thiết kế thân thiện, dễ sử dụng, mang đến hành trình mượt mà và đầy cảm hứng. Hãy để mỗi lần lái xe là một trải nghiệm đáng nhớ!",
            text1: "Khám phá tự do với dịch vụ cho thuê xe máy và ô tô.",
            text2: "Nhận và trả xe ngay tại trung tâm thành phố, thuận tiện và dễ dàng.",
            text3: "Đội ngũ hỗ trợ 24/7, luôn sẵn sàng giúp đỡ bạn.",
            titleR1: "Thủ tục nhanh, hợp đồng rõ ràng",
            titleR2: "Tối ưu chi phí thuê xe máy với cách tính 24h",
            titleR3: "Hỗ trợ giao nhận xe tận nơi 24/7",
        },
        introduce: {
            slogan: "UY TÍN ĐẶT LÊN HÀNG ĐẦU",
            title: "NÂNG TẦM TRẢI NGHIỆM LÁI THÂN THIỆN VÀ DỄ DÀNG",
            content: "T-BIKE chúng tôi luôn mong muốn mang lại cho quý khách những chuyến đi an toàn và thoải mái. Thiết kế thân thiện, dễ sử dụng, mang đến hành trình mượt mà và đầy cảm hứng. Hãy để mỗi lần lái xe là một trải nghiệm đáng nhớ!",
            text1: "DỄ DÀNG ĐẶT THUÊ",
            text2: "CHUYẾN ĐI DÀI HƠN TRONG CẢ NGÀY",
            text3: "NHẬN SỐ DẶM KHÔNG GIỚI HẠN",
        },
        category: {
            titleCategory: "Danh mục xe máy - ô tô T-BIKE",
            content: "Toàn bộ xe máy - ô tô cho thuê của chúng tôi đều là những xe đời cao. Ngoài ra, các xe sau khi kết thúc hợp đồng với khách sẽ được kiểm tra, bảo dưỡng, thay thế các bộ phận hỏng hóc và phải đạt chuẩn an toàn xe trước khi giao cho khách hàng mới.",
            book: "Đặt xe ngay !",
            bookDN: "Tại Đà Nẵng",
            bookNT: "Tại Nha Trang",
            seemore: "XEM THÊM",
        },
        rentalDaNang: {
            category: "Danh mục",
            motorbikes: "Xe Số",
            manualMotorcycle: "Xe Tay Côn",
            scooters: "Xe Tay Ga",
            car: "Xe Ô tô",
            booking: "Đặt Ngay",
        },
        tourist: {
            title: "Địa điểm du lịch",
        },
        contact: {
            title: "LIÊN HỆ VỚI CHÚNG TÔI",
            hotlineDN: "Hotline Đà Nẵng",
            hotlineNT: "Hotline Nha trang",
            time: " Giờ hoạt động ",
            email: " Liên hệ với chúng tôi qua email ",
            phone: "0848 770 770 ",
            locationDN: "Địa chỉ cơ sở tại Đà Nẵng",
            locationNT: "Địa chỉ cơ sở tại Nha Trang",
            beginTime: "Thứ Hai - Thứ Sáu 9.30 sáng - 8 giờ tối",
            lastedTime: "Thứ bảy - Chủ Nhật 10 giờ sáng - 7 giờ tối",
            contentEmail: " tbikedn@gmail.com ",
            contentLocationDN: " 110 Trần Văn Du, Mỹ An, Ngũ Hành Sơn, TP Đà Nẵng ",
            contentLocationNT: "Số 9 Bạch Đằng, Phường Phước Tiến, TP Nha Trang",
            sendMail: "GỬI TIN NHẮN",
            fullName: "Tên đầy đủ ",
            email: "Email",
            subject: "Chủ đề",
            message: "Tin nhắn",
        },
        footer: {
            lastestNews: "ĐỂ LẠI THÔNG TIN",
            subscribe: "ĐĂNG KÝ ĐỂ CẬP NHẬT",
            content: "T-BIKE Chúng tôi cam kết mang đến dịch vụ cho thuê xe máy và ô tô chất lượng, an toàn tuyệt đối. Xe được bảo trì định kỳ, đảm bảo hoạt động tốt nhất. Hãy để T-BIKE đồng hành cùng bạn trên mọi hành trình!",
            contact: "LIÊN HỆ",
            location: "110 Trần Văn Dư, Mỹ An, Ngũ Hành Sơn, TP Đà Nẵng",
            location2: "286 Trần Hưng Đạo, An Hải Tây, Sơn Trà, TP Đà Nẵng",
            location3: "Số 9 Bạch Đằng, Phường Phước Tiến, TP Nha Trang",
            phone: "0848 770 770",
            mail: "tbikedn@gmail.com",
            website: "www.tbikevietnam.com",
            about: "GIỚI THIỆU VỀ T - BIKE",
            phone: "Số Điện Thoại"
        },
        slider: {
            title: "HÌNH ẢNH THỰC TẾ",
        }
    }
};

export default vi;
