import Item01 from '../../assets/img/about1.jpg'
import Item02 from '../../assets/img/about2.jpg'
import Item03 from '../../assets/img/about3.jpg'

export const listAbout = [
    {
        images: Item01,
        title: "about.title1",
        content: "about.content1",
    },
    {
        images: Item02,
        title: "about.title2",
        content: "about.content2",
    },
    {
        images: Item03,
        title: "about.title3",
        content: "about.content3",
    },
];